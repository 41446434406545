import { Booked } from '../components/Thankyou/booked';
import React from 'react';
import { Layout } from '../layout/Layout';
import ReactDOM from 'react-dom';

export default function BookedPage() {
  return (
      <Layout>
        <Booked />
      </Layout>
  );
}



