import { useState } from 'react';
import { Link } from 'react-router-dom';
import { header } from "../../data/data.header";

export const Reset = () => {
  return (
    <>
      {/* <!-- BEGIN DISCOUNT --> */}
      
      <div className="thankyou">
        <div className="container">
          <div className="thankyou-main" id='subscribed'>
            <div className="thankyou-main-content">
              <h2>Thank You!</h2>
              <p>Your request to reset password has been received. We've sent you an email with the  information needed to reset your password. Please check your junk mail to ensure you receive it.</p>


              <div className="thankyou-btn">
                <Link to="/contact" >
                  <a className='btn btn-primary mt-4 register-btn'>CONTACT US</a>
                </Link>
                <br />
                <Link to="/" >
                  <a className='btn btn-primary mt-4 register-btn'>BACK TO HOME</a>
                </Link>
              </div>

              <img src="assets/img/thankyou-img.png" alt="" />

            </div>
          </div>
        </div>
      </div>

      {/* <!-- DISCOUNT EOF   --> */}
    </>
  );
};

