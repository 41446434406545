import { Link } from "react-router-dom";

export const Mocra = () => {
  return (
    <>
      <div className="know-more mocra-sec">
        <div className="container">
          <div className="row">
            <div className="col2 mocra-col">
              <img src="assets/img/mocra-img.png" alt="" className="mocra-img" />
            </div>
            <div className="col1">
              <span className="saint-text">
                Columbia Cosmetics' newest division
              </span>
              <h2>MoCRA Registrar</h2>
              <p>
                As we continue to set ambitious goals helping our customers
                achieve significant growth, along with a wealth of experience in
                manufacturing spanning over 40 years, CCMI is pleased to
                announce the launch of its newest division, MoCRA Registrar.
              </p>
              <p>
                With a mission to provide support and expert guidance, we help
                simplify MoCRA compliance for our clients. Navigating MoCRA
                compliance can be complex and time-consuming. As a reliable and
                trusted partner, MoCRA Registrar is committed to streamline
                compliance processes, and ensure your organization's adherence
                to federal regulations.
              </p>
              <Link to="https://www.mocraregistrar.com/" target="_blank" data-aos="fade-right" className="btn">
                  Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
