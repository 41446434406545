import { useState } from 'react';
import { Link } from 'react-router-dom';
import { header } from "../../data/data.header";

export const Registered = () => {
  return (
    <>
      {/* <!-- BEGIN DISCOUNT --> */}

      {/* <div className="thankyou">
        <div className="container">
            <img src='/assets/img/logo-thankyou.png' alt="" />
            <br />
            <br />
            <p>Thank you for creating an account with <a href="https://columbiacosmetics.com/">Columbia Cosmetics</a>. Your account activation is almost complete.</p>
            <p>As part of our review process, we require that an NDA be signed and submitted. Please review and complete <strong>CCMI Mutual Non-Disclosure Agreement
              <a href="https://na4.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhC2FCLRaQ8Gx2rceSewesWC9e1IPdXBjh3AW0lNngrULBlIrw8-LGb5nJutZ_26kVg*" target="_blank"> here.</a></strong>
            </p>
            <br />
            <Link href='/' ><a className="btn btn-primary">Back to home</a></Link>
        </div>
      </div> */}

      <div className="thankyou">
        <div className="container">
          <div className="thankyou-main">
            <div className="thankyou-main-content">
              <h2>Thank You!</h2>
              <p>Thank you for creating an account with Columbia Cosmetics! <br /> We will be in touch soon. Please check your email for the next steps.</p>
              <p className='thankyou-contact'>Please contact us if you have any questions or concerns.</p>

              <div className="thankyou-btn">
                <Link to="/contact" >
                  <a className='btn btn-primary mt-4 register-btn'>CONTACT US</a>
                </Link>
                <br />
                <Link to="/" >
                  <a className='btn btn-primary mt-4 register-btn'>CONTINUE BROWSING</a>
                </Link>
              </div>

              <img src="assets/img/thankyou-img.png" alt="" />

            </div>
          </div>
        </div>
      </div>

      {/* <!-- DISCOUNT EOF   --> */}
    </>
  );
};

