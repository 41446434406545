import React from 'react';
import { Link } from 'react-router-dom';
export const Contact = () => {
  return (
    <>
      <div className="help private-help">
        <div className="container">
            <div className="row">
                <h3>Let's Work Together</h3>
                <p>Great ideas deserve to be turned into quality products. Let’s discuss how we can support you.</p>
                <Link to='/contact' className="btn btn-primary" >Contact Us</Link>
            </div>
        </div>
      </div>
    </>
  );
};
