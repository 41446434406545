import AuthUser from "../../components/utils/AuthUser";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const BACKOFF_INITIAL_DELAY = 1000;
const MAX_RETRIES = 3;

export const Seo = () => {
    const getPageSlug = () => {
      const [, slug] = window.location.pathname.split("/");
      return slug || "home";
    };
    const slug = getPageSlug();
  
    const { token, logout } = AuthUser();
    const { http } = AuthUser();
  
    const [retryCount, setRetryCount] = useState(0);
    const [seoData, setSeoData] = useState({});
  
    useEffect(() => {
      let retryAttempt = 0;
      const fetchData = async () => {
        http
          .post("/seo-content", {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
            },
            withCredentials: true,
            slug: slug,
          })
          .then((response) => {
            const data = response.data;
            console.log("Fetched SEO data:", data);
            setSeoData(data);
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.status === 429 &&
              retryAttempt < MAX_RETRIES
            ) {
              const delay = BACKOFF_INITIAL_DELAY * Math.pow(2, retryAttempt);
              retryAttempt++;
              setRetryCount(retryAttempt);
              console.log(
                `Rate limited, retrying in ${delay}ms (Attempt ${retryAttempt})`
              );
              setTimeout(fetchData, delay);
            } else {
              console.log("Unhandled error:", error);
            }
          });
      };
      fetchData();
    }, [slug, retryCount]);
  
    useEffect(() => {
    
      // Ensure that seoData contains valid values for meta tags
      if (seoData.meta_description && seoData.meta_keywords) {
        // Set meta tags here
        console.log("Setting meta tags...");
      }
    }, [seoData]);
  
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{seoData?.seo_title}</title>
          <title>{seoData?.seo_title}</title>
          <meta name="description" content={seoData.seo_description}></meta>
          <meta name="keywords" content={seoData.seo_keywordss}></meta>
          <link rel="canonical" href={`https://columbiacosmetics.com/${slug}`}></link>
          <meta property="og:url" href={`https://columbiacosmetics.com/${slug}`} />
          <meta property="og:image" content={"https://columbiacosmetics.com/assets/img/turnkey.png"} />
        </Helmet>
      </>
    );
  };
  