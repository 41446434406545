import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Home from './pages/Home';
import ShopCategory from './pages/ShopCategory';
import Products from './pages/Products';
import Login from './pages/login';
import ContactPage from './pages/contact';
import AboutPage from './pages/about';
import CatalogsPage from './pages/catalogs';
import ShopMain from './pages/shopmain';
import OurFacility from './components/Shopmain/ourfacility';
import OurProducts from './pages/ourproducts';
import ServicePage from './pages/services';
import ProductDevelopment from './pages/productdevelopment';
import WhiteLabel from './pages/whitelabel';
import PrivateLabel from './pages/privatelabel';
import MakeupPage from './pages/makeup';
import SkinCare from './pages/skincare';
import Dashboard from './pages/dashboard';
import CartPage from './pages/cart';
import PaymentPage from './pages/payment';
import Register from './pages/register';
import ThankyouRegister from './pages/registered';
import Thankyou from './pages/thankyou';
import DirectPayThankyou from './pages/ordered';
import CheckPayThankyou from './pages/order';
import CardThankyou from './pages/ordersuccess';
import CatalogDetails from './pages/catalogdetails';
import ResetThankyou from './pages/resetthankyou';
import ResetPage from './pages/reset';
import PrivacyPage from './pages/privacy';
import TermsPage from './pages/terms';
import BookedPage from './pages/booked';

import '../src/styles/styles.scss';

import SmoothScroll from './components/SmoothScroll';
import { UserProvider } from './Contexts/LoginContext';
import { NavProvider } from './Contexts/DynamicNav';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Router>
        <SmoothScroll>
            <UserProvider>
                <NavProvider>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/shop/:category" element={<ShopCategory />} />
                        <Route path="/:parent/:slug" element={<Products />} />
                        <Route path="/:parent/:subcat/:slug" element={<Products />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/contact" element={<ContactPage />} />
                        <Route path="/about" element={<AboutPage />} />
                        <Route path="/catalogs" element={<CatalogsPage />} />
                        <Route path="/shopmain" element={<ShopMain />} />
                        <Route path="/shop" element={<ShopCategory />} />
                        <Route path="/:category" element={<ShopCategory />} />
                        <Route path="/ourfacility" element={<OurFacility />} />
                        <Route path="/ourproducts" element={<OurProducts />} />
                        <Route path="/services" element={<ServicePage />} />
                        <Route path="/services/productdevelopment" element={<ProductDevelopment />} />
                        <Route path="/services/whitelabel" element={<WhiteLabel />} />
                        <Route path="/services/privatelabel" element={<PrivateLabel />} />
                        <Route path="/make-up" element={<MakeupPage />} />
                        <Route path="/skin-care" element={<SkinCare />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/cart" element={<CartPage />} />
                        <Route path="/payment" element={<PaymentPage />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/registered" element={<ThankyouRegister />} />
                        <Route path="/thankyou" element={<Thankyou />} />
                        <Route path="/ordered" element={<DirectPayThankyou />} />
                        <Route path="/order" element={<CheckPayThankyou />} />
                        <Route path="/ordersuccess" element={<CardThankyou />} />
                        <Route path="/catalogs/:name" element={<CatalogDetails />} />
                        <Route path="/resetthankyou" element={<ResetThankyou />} />
                        <Route path="/reset/:resetToken" element={<ResetPage />} />
                        <Route path="/privacy" element={<PrivacyPage />} />
                        <Route path="/terms" element={<TermsPage />} />
                        <Route path="/booked" element={<BookedPage />} />
                        
                    </Routes>
                </NavProvider>
            </UserProvider>
        </SmoothScroll>
    </Router >
);

reportWebVitals();
