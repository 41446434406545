import { Link } from "react-router-dom";
import { useContext, useEffect, useState, useRef } from "react";
import $ from "jquery";
export const CcmiLabs = () => {
    
  return (
    <>
      <div className="facility-overviews new-facility-overview">
        <div className="container">
          <div className="fac-content">
            <p>Columbia Cosmetics Manufacturing, Inc.</p>
            <h3>welcomes</h3>
            <img src="assets/img/ccmilogo-dark.png" alt="" />
            <p className="p-bold">
              <b>
                <i>
                  Two great companies coming together to bring beauty
                  manufacturing excellence.
                </i>
              </b>
            </p>
            <p>
              For over 40 years, Columbia Cosmetics Manufacturing Inc. (CCMI)
              has grown to be one of the largest cosmetic manufacturers in North
              America. Our commitment towards helping companies and
              entrepreneurs strive in the beauty industry through our products
              and services remains our top priority.
            </p>
            <p>
              As we continue to set ambitious goals helping our customers
              achieve significant growth, we are celebrating a new era of
              innovation through our acquisition of Verde Labs, renamed
              ccmiLABS, a body care manufacturing company based in Los Angeles.
              ccmiLABS has a wealth of skincare, hair care, and body care
              formulas and has been an innovator in the natural beauty care
              industry for over a decade.
            </p>
            <p>
              This collaborative move expands our manufacturing capabilities and
              capacity, our formulation libraries, our experience with natural
              beauty, and our ability to deliver extended innovative solutions
              to our customers.
            </p>
            <p>
              With a sense of pride and optimism, we are excited for the future
              and look forward to collaborating with our current and future
              partners.
            </p>
          </div>
        </div>
      </div>
      <div className="facility-gallery new-gallery">
        <div className="container">
          <div className="row">
            <div className="col">
                <img src="assets/img/ccmilabs-slider-img.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
