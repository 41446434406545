import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
export const Booking = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [datetime, setDateTime] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const booking = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.columbiacosmetics.com/api/booking', {
        headers: {
          "X-Requested-with": "XMLHttpRequest",
        },
        withCredentials: true,
        name: name,
        email: email,
        datetime: datetime,
        message: message
      });

      const data = response.data;
      console.log(response);

      if (data?.message) {
        navigate('/booked');
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <>
      {/* <!-- BEGIN DISCOUNT --> */}
      <div className="discount discount-contacts js-img booking">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="discount-info">
                <span className="saint-text">
                  Let's Connect
                </span>
                <span className="main-text">
                  Schedule an Appointment
                </span>
                <p>
                  Our showrooms are open for in-person or virtual appointments to
                  discover our products, and services. Along with our team of
                  experts, we can explore and help find the best solution for you.
                </p>
                <form onSubmit={booking}>
                  <div className="box-field">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Name*"
                      value={name}
                      onChange={e => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="box-field">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Email*"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="box-field">
                    <input
                      className="form-control"
                      type="datetime-local"
                      name="date-time"
                      value={datetime}
                      onChange={e => setDateTime(e.target.value)}
                      required
                    />
                  </div>
                  <div className="box-field">
                    <select
                      name="location"
                      id="location"
                      className="form-control"
                      required
                    >
                      <option selected disabled>Showroom Location</option>
                      <option value="San Leandro, CA">San Leandro, CA</option>
                      <option value="Northridge, CA">Northridge, CA</option>
                    </select>
                  </div>
                  <div className="box-field box-field__textarea">
                    <textarea
                      className="form-control"
                      name="message"
                      placeholder="Message*"
                      rows={8}
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      required
                    ></textarea>
                  </div>
                  <button type="submit" className="btn">
                    Book Now
                  </button>
                </form>
              </div>
            </div>
            <div className="col col-img">
              <img src="assets/img/contact-main.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- DISCOUNT EOF   --> */}
    </>
  );
};
